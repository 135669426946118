import React from "react"

import { PageType } from "../index"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { getCurrentLangKey } from "ptz-i18n"
import { graphql, useStaticQuery } from "gatsby"

const NotFoundPageEn: React.FC = () => {
    return <>
        <SEO title="404: Page not found" />
        <section className="text-center">
            <h1 className="mb-6">Page not found</h1>
            <p>This page could not be found.</p>
        </section>
    </>
}

const NotFoundPageDe: React.FC = () => {
    return <>
        <SEO title="404: Seite nicht gefunden" />
        <section className="text-center">
            <h1 className="mb-6">Seite nicht gefunden</h1>
            <p>Diese Seite konnte nicht gefunden werden.</p>
        </section>
    </>
}

const NotFoundPage: PageType = ({ location }) => {
    const {
        site: {
            siteMetadata: {
                languages: {
                    langs,
                    defaultLangKey,
                }
            }
        }
    } = useStaticQuery(graphql`        
        query Lang404 {
            site {
                siteMetadata {
                    languages {
                        defaultLangKey
                        langs
                    }
                }
            }
        }
    `)

    const langKey = getCurrentLangKey(langs, defaultLangKey, location?.pathname || "/") as "de" | "en"
    return <Layout location={location}>
        {langKey === "de" && <NotFoundPageDe />}
        {langKey !== "de" && <NotFoundPageEn />}
    </Layout>
}

export default NotFoundPage
